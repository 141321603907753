<template>
  <div class="header-filter">
    <div class="filter-marg"></div>
    <!-- search id -->
    <div class="search-block-id">
      <input
        type="number"
        v-model="searchId"
        placeholder="Введіть id об'єкту"
      />
      <div class="btn-filter" @click="seachById">Пошук</div>
    </div>

    <div class="filter-main-title d-flex align-items-center">
      <div class="base-text">БАЗА</div>
      <b-dropdown
        class="dropdown-apartments ml-1"
        :text="type_constant_plural[$store.state.filter_header.buildingType]"
      >
        <b-dropdown-item
          v-for="(label, index) in types"
          @click="updateBuildingType(index)"
          :key="index"
          id="dropdown-menu"
        >
          {{ label }}
          <span class="status-options" v-if="index === 'lands'"> (скоро) </span>
          <span class="status-options" v-if="index === 'garage'">
            (скоро)
          </span>
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        class="dropdown-apartments ml-1"
        :text="getNameBusinessType"
        style="padding-left: 5px"
      >
        <b-dropdown-item
          v-for="(item, index) in businessType"
          @click="updateBusinessType(item.key)"
          :key="index"
          id="dropdown-menu"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'FilterHeader',
  mixins: [LabelsMixin],
  data() {
    return {
      businessType: [
        {
          key: 'sell',
          name: 'Продам'
        },
        {
          key: 'rent',
          name: 'Здам'
        }
      ]
    };
  },
  methods: {
    updateBuildingType(type) {
      this.$store.commit('SET_BUILDING_TYPE', type);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateBusinessType(key) {
      this.$store.commit('SET_BUSINESS_TYPE', key);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateFilter(isSearchById = false) {
      if (window.innerWidth > 991) {
        // document.getElementsByClassName(
        //   'simplebar-content-wrapper'
        // )[0].scrollLeft = 0; // for desktop
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // for mobile
      }

      if (!isSearchById) {
        this.$store.dispatch('resetFilters');
      }

      this.$emit('forceRerender');
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('getObjects', { isSearchById });
    },
    seachById() {
      if (!this.$store.state.filter_header.searchId) {
        this.$notify({
          group: 'app',
          type: 'info',
          text: 'id не может быть пустым'
        });
        return;
      }

      this.updateFilter(true);
    }
  },
  computed: {
    getNameBusinessType() {
      return this.businessType.find(
        (i) => i.key === this.$store.state.filter_header.businessType
      ).name;
    },
    searchId: {
      get() {
        return this.$store.state.filter_header.searchId;
      },
      set(val) {
        this.$store.commit('SET_SEARCH_ID', val);
      }
    }
  }
};
</script>
