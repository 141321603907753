<template>
  
  <filter-item-wrapper
    title="Опції"
    icon="menu_option"
    collapse="collapse-option"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterOption"
        @click="clear()"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <b-container class="form-option-filter">
        <div
          v-if="isFlatAndRent"
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_pets_allowed"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Можна з тваринами</span>
            <img
              src="@/assets/images/allow_pets.svg"
              alt="Allow pets"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          v-if="isFlatAndRent"
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_children_allowed"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Можна з дітьми</span>
            <img
              src="@/assets/images/allow_children.svg"
              alt="Allow children"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          v-if="isFlatAndRent"
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_foreigners_allowed"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Беруть іноземців</span>
            <img
              src="@/assets/images/allow-foreigners.svg"
              alt="Allow foreigners"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_used_for_advertising"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Можна використовувати в рекламі</span>
            <img
              src="@/assets/images/not_show_without_moderation.svg"
              alt="Not show without moderation"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_seller_pays_the_commission"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Продавець оплачує комісію</span>
            <img
              src="@/assets/images/seller_pays_commissitions.svg"
              alt="Seller pays commissitions"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="exclude_unmoderated"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Не показувати об`єкти без модерації</span>
            <img
              src="@/assets/images/allow_use_advertise.svg"
              alt="Allow use advertise"
              width="20"
              height="20"
            />
          </label>
        </div>

        <div
          id="checkers"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
          v-if="businessType === 'sell'"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="is_cashless_payment_available"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Можливий безготівковий розрахунок</span>
            <img
              src="@/assets/images/cashless_payments_filter.png"
              alt="Cashless payments filter"
              width="20"
              height="20"
            />
          </label>
        </div>
      </b-container>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import eCheckbox from '@/elements/inputs/e-checkbox';
import { mapState, mapGetters, mapMutations } from 'vuex';
import component from '@/mixins/component';

export default {
  mixins: [component],
  components: {
    FilterItemWrapper,
    eCheckbox
  },
  computed: {
    isFlatAndRent() {
      return this.buildingType === 'flat' && this.businessType === 'rent'
    },
    ...mapState({
      businessType: (state) => state.filter_header.businessType,
      buildingType: (state) => state.filter_header.buildingType,
    }),
    ...mapGetters({
      existsTurnOnFilterOption: 'existsTurnOnFilterOption'
    }),
    is_children_allowed: {
      get() {
        return this.$store.state.filter_option.form.is_children_allowed
      },
      set(val) {
        this.changeIsChildren(val)
      }
    },
    is_foreigners_allowed: {
      get() {
        return this.$store.state.filter_option.form.is_foreigners_allowed
      },
      set(val) {
        this.changeForeigners(val)
      }
    },
    is_pets_allowed: {
      get() {
        return this.$store.state.filter_option.form.is_pets_allowed
      },
      set(val) {
        this.changePets(val)
      }
    },
    is_seller_pays_the_commission: {
      get() {
        return this.$store.state.filter_option.form.is_seller_pays_the_commission
      },
      set(val) {
        this.changeSellerPays(val)
      }
    },
    is_used_for_advertising: {
      get() {
        return this.$store.state.filter_option.form.is_used_for_advertising
      },
      set(val) {
        this.changeAdvertising(val)
      }
    },
    exclude_unmoderated: {
      get() {
        return this.$store.state.filter_option.form.exclude_unmoderated
      },
      set(val) {
        this.changeExcludeUnderated(val)
      }
    },
    is_cashless_payment_available: {
      get() {
        return this.$store.state.filter_option.form.is_cashless_payment_available
      },
      set(val) {
        this.changeCashlessPayment(val)
      }
    }
  },
  methods: {
    ...mapMutations({
      clear: 'RESET_FILTER_OPTION',
      changeIsChildren: 'CHANGE_IS_CHILDREN',
      changeForeigners: 'CHANGE_FOREIGNERS',
      changePets: 'CHANGE_PETS',
      changeSellerPays: 'CHANGE_SELLER_PAYS',
      changeAdvertising: 'CHANGE_ADVERTISING',
      changeExcludeUnderated: 'CHANGE_EXCLUDE_UNMODERATED',
      changeCashlessPayment: 'CHANGE_CASHLESS_PAYMENT'
    }),
  }
};
</script>

<style lang="sass">
  .form-option-filter
    img
      margin-left: 5px
</style>