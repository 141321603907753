<template>
  <filter-item-wrapper
    title="Площа"
    icon="menu_icon4"
    collapse="collapse-square"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterSquare"
        @click="changeFilterSquare"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <div class="col-6">
          <div v-if="isSquareCommon" class="form-group form-group-filter" >
            <label class="filter-modal-label">Загальна площа від (М²)</label>
            <input
              v-model="square_common_from"
              type="number"
              class="form-input"
            />
          </div>

          <div v-if="buildingType !== 'commerce'" class="form-group form-group-filter">
            <label class="filter-modal-label">Площа кухні від</label>
            <input
              v-model="square_kitchen_from"
              type="number"
              name="states[]"
              class="form-input"
            />
          </div>

          <div
            v-if="buildingType === 'flat'"
            class="form-group form-group-filter"
          >
            <label class="filter-modal-label">Житлова площа від</label>
            <input
              v-model="square_living_from"
              type="number"
              name="states[]"
              class="form-input"
            />
          </div>
        </div>

        <div  v-if="isSquareCommon" class="col-6">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">Загальна площа до (М²)</label>
            <input
              v-model="square_common_to"
              type="number"
              name="states[]"
              class="form-input"
            />
          </div>
          <div
             v-if="buildingType !== 'commerce'" 
            class="form-group form-group-filter"
          >
            <label class="filter-modal-label">Площа кухні до (М²)</label>
            <input
              v-model="square_kitchen_to"
              type="number"
              name="states[]"
              class="form-input"
            />
          </div>
          <div
            v-if="buildingType === 'flat'"
            class="form-group form-group-filter"
          >
            <label class="filter-modal-label">Житлова площа до (М²)</label>
            <input
              v-model="square_living_to"
              type="number"
              name="states[]"
              class="form-input"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-6">
            <div
              v-if="isSquareArea"
              class="form-group form-group-filter"
            >
              <label class="filter-modal-label">
                Площа ділянки від (сотки)
              </label>
              <input
                v-model="square_area_from"
                type="number"
                name="states[]"
                class="form-input"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              v-if="isSquareArea"
              class="form-group form-group-filter"
            >
              <label class="filter-modal-label">
                Площа ділянки до (сотки)
              </label>
              <input
                v-model="square_area_to"
                type="number"
                name="states[]"
                class="form-input"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterSquare',
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changeSquareCommonFrom: 'CHANGE_SQUARE_COMMON_FROM',
      changeSquareCommonTo: 'CHANGE_SQUARE_COMMON_TO',
      changeSquareKitchenFrom: 'CHANGE_SQUARE_KITCHEN_FROM',
      changeSquareKitchenTo: 'CHANGE_SQUARE_KITCHEN_TO',
      changeSquareAreaFrom: 'CHANGE_SQUARE_AREA_FROM',
      changeSquareAreaTo: 'CHANGE_SQUARE_AREA_TO',
      changeSquareLivingFrom: 'CHANGE_SQUARE_LIVING_FROM',
      changeSquareLivingTo: 'CHANGE_SQUARE_LIVING_TO',
      changeFilterSquare: 'RESET_FILTER_SQUARE'
    })
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    ...mapGetters({
      existsTurnOnFilterSquare: 'existsTurnOnFilterSquare'
    }),
    isSquareArea() {
        if (this.aptTypes.length !== 0 && this.buildingType === 'commerce') {
          let aptTypes = ['restaurant_or_cafe_or_bar', 'warehouse_or_hangar', 'recreation_center_or_hotel', 'industrial_premise', 'free_appointment_premise', 'farm', 'event_object', 'production_object'];
          return this.aptTypes.some( type => aptTypes.includes(type.id));
        }
        return this.buildingType === 'house' || this.buildingType === 'commerce' ;
    },
    isSquareCommon() { 
      if (this.aptTypes.length !== 0 && this.buildingType === 'commerce') {
        let aptTypes = [
          'farm'
        ];
        return !this.aptTypes.every( type => aptTypes.includes(type.id));
      }
      return true;
    },
    
    square_common_from: {
      get() {
        return this.$store.state.filter_square.form.square_common_from;
      },
      set(data) {
        this.changeSquareCommonFrom(data);
      }
    },
    square_common_to: {
      get() {
        return this.$store.state.filter_square.form.square_common_to;
      },
      set(data) {
        this.changeSquareCommonTo(data);
      }
    },
    square_kitchen_from: {
      get() {
        return this.$store.state.filter_square.form.square_kitchen_from;
      },
      set(data) {
        this.changeSquareKitchenFrom(data);
      }
    },
    square_kitchen_to: {
      get() {
        return this.$store.state.filter_square.form.square_kitchen_to;
      },
      set(data) {
        this.changeSquareKitchenTo(data);
      }
    },
    square_area_from: {
      get() {
        return this.$store.state.filter_square.form.square_area_from;
      },
      set(data) {
        this.changeSquareAreaFrom(data);
      }
    },
    square_area_to: {
      get() {
        return this.$store.state.filter_square.form.square_area_to;
      },
      set(data) {
        this.changeSquareAreaTo(data);
      }
    },
    square_living_from: {
      get() {
        return this.$store.state.filter_square.form.square_living_from;
      },
      set(data) {
        this.changeSquareLivingFrom(data);
      }
    },
    square_living_to: {
      get() {
        return this.$store.state.filter_square.form.square_living_to;
      },
      set(data) {
        this.changeSquareLivingTo(data);
      }
    }
  }
};
</script>
