<template>
  <div class="item-filter">
    <div class="m-1 d-flex item-filter__row">
      <div
        v-b-toggle="collapse"
        class="d-flex align-items-center justify-content-start w-100"
      >
        <span class="plus-filter" />
        <icon :name="icon" class="filter-item-icon" />
        <span class="header-title">{{ title }}</span>
      </div>
      <slot name="clear"> </slot>
    </div>
    <b-collapse :id="collapse">
      <b-card class="type-filter">
        <slot name="content"></slot>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'FilterItemWrapper',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    collapse: {
      type: String
    }
  }
};
</script>
