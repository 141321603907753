<template>
  <filter-item-wrapper
    title="Позначка"
    icon="menu_icon8"
    collapse="collapse-label"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterLabels"
        @click="resetFilterLabels"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <b-card>
        <multiselect
          v-model="labels"
          placeholder="Позначка"
          label="name"
          :searchable="false"
          track-by="id"
          :close-on-select="false"
          :clear-on-select="false"
          :show-no-results="false"
          :show-labels="false"
          :options="user_labels_array"
          :multiple="true"
        >
          <!-- Шаблон для отображения опции с иконкой -->
          <template v-slot:option="{ option }">
            <div>
              <img :src="icons[option.id]" alt="icon" width="10">
              {{ option.name }}
            </div>
          </template>
        </multiselect>
      </b-card>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import LabelsMixin from '@/mixins/LabelsMixin';
import icon0 from '@/assets/object-actions/good.svg';
import icon1 from '@/assets/object-actions/bad.svg';
import icon2 from '@/assets/object-actions/smile.svg';
import icon3 from '@/assets/object-actions/force.svg';
import icon4 from '@/assets/object-actions/telescope.svg';
import icon5 from '@/assets/object-actions/plus.svg';
import icon6 from '@/assets/object-actions/minus.svg';
import icon7 from '@/assets/object-actions/sun.svg';
import icon8 from '@/assets/object-actions/phone.svg';
import icon9 from '@/assets/object-actions/stone.svg';

export default {
  name: 'FilterLabel',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      icons: {
        liquid: icon0,
        nonliquid: icon1,
        owner: icon2,
        fast_sell: icon3,
        alternate: icon4,
        actual: icon5,
        nonactual: icon6,
        rieltor: icon7,
        recall: icon8,
        exclusive: icon9
      }
    };
  },
  methods: {
    ...mapMutations({
      changeLabels: 'CHANGE_LABELS',
      resetFilterLabels: 'RESET_FILTER_LABELS'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterLabels: 'existsTurnOnFilterLabels'
    }),
    labels: {
      get() {
        return this.$store.state.filter_label.form.user_label;
      },
      set(data) {
        this.changeLabels(data);
      }
    }
  }
};
</script>
