<template>
  <filter-item-wrapper
    title="Поверховість"
    icon="menu_icon3"
    collapse="collapse-number-of-storeys"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterNumberOfStoreys"
        @click="resetFilterNumberOfStoreys"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <div class="col-6" v-if="isFloor">
          <div class="form-group form-group-filter">
            <label for="floorFrom" class="filter-modal-label">Поверх від</label>
            <select
              v-model="floor_from"
              name="floorFrom"
              id="floorFrom"
              class="form-input form-select"
            >
              <option value="" selected></option>
              <option v-for="f in 30" :value="f" :key="f">{{ f }}</option>
            </select>
          </div>
        </div>

        <div class="col-6" v-if="isFloor">
          <div class="form-group form-group-filter">
            <label for="floorTo" class="filter-modal-label">Поверх до</label>
            <select
              v-model="floor_to"
              name="floorTo"
              id="floorTo"
              class="form-input form-select"
            >
              <option value="" selected></option>
              <option value="not-last">Не последний</option>
              <option v-for="f in 30" :value="f" :key="f">{{ f }}</option>
            </select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group form-group-filter">
            <label for="storeysFrom" class="filter-modal-label">
              <span>Поверховість від</span>
            </label>
            <select
              v-model="floors_from"
              id="storeysFrom"
              name="storeysFrom"
              class="form-input form-select"
            >
              <option value="" selected></option>
              <option v-for="f in 30" :value="f" :key="f">{{ f }}</option>
            </select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group form-group-filter">
            <label for="storeysTo" class="filter-modal-label">
              <span>Поверховість до </span>
            </label>
            <select
              v-model="floors_to"
              id="storeysTo"
              name="storeysTo"
              class="form-input form-select"
            >
              <option value="" selected></option>
              <option v-for="f in 30" :value="f" :key="f">{{ f }}</option>
            </select>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterNumberOfStoreys',
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changeFloorFrom: 'CHANGE_FLOOR_FROM',
      changeFloorTo: 'CHANGE_FLOOR_TO',
      changeFloorsFrom: 'CHANGE_FLOORS_FROM',
      changeFloorsTo: 'CHANGE_FLOORS_TO',
      resetFilterNumberOfStoreys: 'RESET_FILTER_NUMBER_OF_STOREYS'
    })
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    ...mapGetters({
      existsTurnOnFilterNumberOfStoreys: 'existsTurnOnFilterNumberOfStoreys'
    }),
    isFloor() {
      if (this.aptTypes.length !== 0 && this.buildingType === 'commerce') {
        let aptTypes = [
          'restaurant_or_cafe_or_bar', 
          'office_room', 'shop', 
          'beauty_object', 
          'health_object', 
          'sport_object', 
          'event_object',
        ];
        return this.aptTypes.some( type => aptTypes.includes(type.id));
      }
      return this.buildingType !== 'house';
    },
    floor_from: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floor_from;
      },
      set(data) {
        this.changeFloorFrom(data.toString());
      }
    },
    floor_to: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floor_to;
      },
      set(data) {
        this.changeFloorTo(data.toString());
      }
    },
    floors_from: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floors_from;
      },
      set(data) {
        this.changeFloorsFrom(data.toString());
      }
    },
    floors_to: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floors_to;
      },
      set(data) {
        this.changeFloorsTo(data.toString());
      }
    }
  }
};
</script>
