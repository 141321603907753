<template>
    <div class="filter-sort d-flex align-items-center px-3 pb-4">
      <span class="mr-2" style="color: white">Сортувати:</span>
      <div class="flex-grow-1">
        <b-form-group button-variant="secondary" class="mb-0">
            <e-multiselect
                :close-on-select="false"
                :clear-on-select="false"
                :show-no-results="false"
                label="name"
                track-by="id"
                :show-labels="false"
                v-on="$listeners"
                :multiple="false"
                v-bind="$attrs"
                :options="$options.optionsSort"
                v-model="sortable"
                :searchable="false"
                >
            </e-multiselect>
        </b-form-group>
      </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, } from 'vuex';
import eMultiselect from '../../../../elements/e-multiselect';
import options from './filter-sort-options'

export default {
    optionsSort: options,
    components: { eMultiselect },
    methods: {
        ...mapMutations({
            changeSortable: 'CHANGE_SORTABLE',
        }),
    },
    computed: {
        ...mapGetters({
            sortableState: 'getSortable'
        }),
        sortable: {
            get() {
                return this.$options.optionsSort.find(({ id }) => id === this.sortableState)
            },
            set(data) {
                this.changeSortable(data.id);
                this.$emit('changeSortable')
            }
        }
    }
}
</script>