<template>
  <filter-item-wrapper title="Вартість" icon="menu_icon6" collapse="collapse-price">
    <template #clear>
      <span
        v-if="existsTurnOnFilterPrice"
        @click="clear"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <div class="col-6">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">
              Від
              <span v-if="businessType === 'rent'"> грн </span>
              <span v-else> $ </span>
            </label>
            <input type="number" v-model="price_from" class="form-input" />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">
              До
              <span v-if="businessType === 'rent'"> грн </span>
              <span v-else> $ </span>
            </label>
            <input
              type="number"
              v-model="price_to"
              name="states[]"
              class="form-input"
            />
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'FilterPrice',
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changePriceFrom: 'CHANGE_PRICE_FROM',
      changePriceTo: 'CHANGE_PRICE_TO',
      resetFilterPrice: 'RESET_FILTER_PRICE'
    }),
    sorting(type, field) {
      this.$refs[field].classList.add('active');
      this.$refs[type].classList.remove('active');
    },
    clear() {
      this.resetFilterPrice();
    }
  },
  computed: {
    ...mapState({
      businessType: (state) => state.filter_header.businessType
    }),
    ...mapGetters({
      existsTurnOnFilterPrice: 'existsTurnOnFilterPrice'
    }),
    price_from: {
      get() {
        return this.$store.state.filter_price.form.price_from;
      },
      set(data) {
        this.changePriceFrom(data);
      }
    },
    price_to: {
      get() {
        return this.$store.state.filter_price.form.price_to;
      },
      set(data) {
        this.changePriceTo(data);
      }
    },
  },
};
</script>
