<template>
  <filter-item-wrapper
    title="Джерело"
    icon="menu_icon10"
    collapse="collapse-source"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterSource"
        @click="resetFilterSource"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <b-card>
        <div class="form-row">
          <div class="col-6 col-md-12">
            <div
              v-for="(label, index) in source_labels"
              :key="index"
              class="btn-group-toggle form-checkbox-b"
              data-toggle="buttons"
            >
              <label class="btn">
                <input
                  v-model="source"
                  type="checkbox"
                  :value="index"
                  autocomplete="off"
                />
                <icon
                  name="menu_istochnic_icon1"
                  class="btn-menu-inner-icon"
                  v-model="source"
                />
                <span>{{ label }}</span>
              </label>
            </div>
          </div>
        </div>
      </b-card>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters } from 'vuex';
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'FilterSourse',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changeSource: 'CHANGE_SOURCE',
      resetFilterSource: 'RESET_FILTER_SOURCE'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterSource: 'existsTurnOnFilterSource'
    }),
    source: {
      get() {
        return this.$store.state.filter_source.form.source;
      },
      set(data) {
        this.changeSource(data);
      }
    }
  }
};
</script>
