<template>
  <div :class="$bem()" v-if="renderComponent">
    <FilterHeader @forceRerender="forceRerender" />
    <div
      :class="$bem('filter-options')"
      :style="{ height: optionsHeight + 'px' }"
    >
      <FilterSort @changeSortable="filter" />
      <FilterAddress />
      <FilterType />
      <FilterNumberOfStoreys v-if="isNumberOfStoreys" />
      <FilterSquare />
      <FilterOptions v-if="isOptions" />
      <FilterCondition />
      <FilterCommunications v-if="buildingType === 'house'" />
      <FilterPrice />
      <FilterPhone />
      <FilterOption />
      <FilterLabel />
      <FilterDate />
      <FilterSource />
    </div>

    <div :class="$bem('filter-actions')">
      <div class="btn-filter" @click="filterClear">Очистити пошук</div>
      <div class="btn-filter" @click="filter">Застосувати</div>
    </div>
  </div>
</template>

<script>
import FilterHeader from '@/components/app/filters/filter-header/FilterHeader';
import FilterAddress from '@/components/app/filters/filter-address/FilterAddress';
import FilterType from '@/components/app/filters/filter-type/FilterType';
import FilterNumberOfStoreys from '@/components/app/filters/filter-number-of-storeys/FilterNumberOfStoreys';
import FilterSquare from '@/components/app/filters/filter-square/FilterSquare';
import FilterCondition from '@/components/app/filters/filter-condition/FilterCondition';
import FilterCommunications from '@/components/app/filters/filter-comunication/FilterCommunications';
import FilterPrice from '@/components/app/filters/filter-price/FilterPrice';
import FilterPhone from '@/components/app/filters/filter-phone/FilterPhone';
import FilterLabel from '@/components/app/filters/filter-label/FilterLabel';
import FilterDate from '@/components/app/filters/filter-date/FilterDate';
import FilterSource from '@/components/app/filters/filter-source/FilterSource';
import FilterSort from '@/components/app/filters/filter-sort';
import FilterOptions from '@/components/app/filters/filter-options/FilterOptions';
import FilterOption from '@/components/app/filters/filter-option/FilterOption';
import { mapState, mapActions, mapMutations } from 'vuex';
import component from '@/mixins/component';

export default {
  name: 'filters',
  components: {
    FilterHeader,
    FilterAddress,
    FilterType,
    FilterNumberOfStoreys,
    FilterSquare,
    FilterCondition,
    FilterCommunications,
    FilterPrice,
    FilterPhone,
    FilterLabel,
    FilterDate,
    FilterSource,
    FilterSort,
    FilterOptions,
    FilterOption
  },
  mixins: [component],
  data() {
    return {
      renderComponent: true,
      optionsHeight: window.innerHeight - 193,
    };
  },
  methods: {
    ...mapMutations({
      setPage: 'SET_PAGE'
    }),
    ...mapActions(['getObjects']),
    filter() {
      if (window.innerWidth > 991) {
        // document.getElementsByClassName(
        //   'simplebar-content-wrapper'
        // )[0].scrollLeft = 0; // for desktop
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // for mobile
      }

      this.$store.commit('SET_SEARCH_ID', '');
      this.setPage(1);
      this.getObjects('flats');
      this.forceRerender();

      this.$emit('close-filter-sidebar');
    },
    filterClear() {
      this.$store.dispatch('resetFilters');
      this.forceRerender();
      this.filter();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    updateOptionsHeight() {
      this.optionsHeight = window.innerHeight - 193;
    }
  },
  created() {
    window.addEventListener('resize', this.updateOptionsHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateOptionsHeight);
  },
  watch: {
    optionsHeight() {},
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    isNumberOfStoreys() {
      if (this.aptTypes.length !== 0 && this.buildingType === 'commerce') {
        let aptTypes = [
          'recreation_center_or_hotel',
          'industrial_premise',
          'free_appointment_premise',
          'MAF',
          'autoservice',
          'farm',
          'trading_area',
          'production_object'
        ];
        return !this.aptTypes.every((type) => aptTypes.includes(type.id));
      }
      return true;
    },
    isOptions() {
      if (this.aptTypes.length !== 0 && this.buildingType === 'commerce') {
        let aptTypes = ['MAF', 'farm', 'trading_area'];
        return !this.aptTypes.every((type) => aptTypes.includes(type.id));
      }
      return this.buildingType === 'commerce';
    }
  }
};
</script>

<style lang="sass">
.filters
  &__filter-options
    padding-top: 25px
    overflow-y: auto
    overflow-x: hidden
    &::-webkit-scrollbar
      width: 0px

  &__filter-actions
    padding-left: 10px
    padding-top: 15px
    margin-bottom: 20px
    width: 100%
</style>
