<template>
  <filter-item-wrapper
    title="Телефон"
    icon="menu_icon7"
    collapse="collapse-phone"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterPhone"
        @click="resetFilterPhone"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">Введіть номер</label>
            <div class="d-flex align-items-end block-phone">
              <input
                style="width: 40px"
                v-model="countryCode"
                type="text"
                disabled
                class="form-input required"
              />
              <input
                v-model="phone"
                type="states[]"
                class="form-input required"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterPhone',
  components: {
    FilterItemWrapper
  },
  data() {
    return {
      countryCode: '+38'
    };
  },
  methods: {
    ...mapMutations({
      changePhone: 'CHANGE_PHONE',
      resetFilterPhone: 'RESET_FILTER_PHONE'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterPhone: 'existsTurnOnFilterPhone'
    }),
    phone: {
      get() {
        return this.$store.state.filter_phone.form.phone;
      },
      set(data) {
        this.changePhone(data);
      }
    }
  }
};
</script>
