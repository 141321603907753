<template>
  <filter-item-wrapper
    title="Адреса"
    icon="menu_icon1"
    collapse="collapse-address"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterAddress"
        @click="resetFilterAddress"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="row">
        <div class="form-group form-group-filter col-6">
          <label class="filter-modal-label">Місто</label>
          <multiselect
            v-model="city"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :options="[
              ...optionsCities,
              {
                id: 3,
                is_city: false,
                name: 'АР Kрим (скоро)',
                $isDisabled: true
              }
            ]"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
        <div class="form-group form-group-filter col-6">
          <label class="filter-modal-label">{{
            isEnabledSuburb ? 'Район' : 'Адмін-район'
          }}</label>
          <multiselect
            v-model="district"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :options="optionsDistricts"
            :multiple="true"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
      </div>

      <div class="row">
        <div
          v-if="!isEnabledSuburb"
          class="form-group form-group-filter col-12"
        >
          <label class="filter-modal-label">Мікрорайон</label>
          <multiselect
            v-model="microdistrict"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :multiple="true"
            :options="optionsMicroDistricts"
            :internalSearch="false"
            @search-change="findMicrodistrict"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
        <div class="form-group form-group-filter col-12">
          <label class="filter-modal-label">Вулиця</label>
          <multiselect
            v-model="street"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :options="optionsStreets"
            :multiple="true"
            :internalSearch="false"
            @search-change="findStreets"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
        <div v-if="isEnabledSuburb" class="form-group form-group-filter col-6">
          <label class="filter-modal-label">Номер будинку</label>
          <input v-model="house_number" class="form-input" />
        </div>
      </div>
      <div v-if="!isEnabledSuburb" class="row align-content-center">
        <div class="form-group form-group-filter col-6">
          <label class="filter-modal-label">Номер будинку</label>
          <input v-model="house_number" class="form-input" />
        </div>
        <div
          v-if="$store.state.filter_header.buildingType !== 'commerce'"
          class="form-group form-group-filter col-6"
        >
          <label class="filter-modal-label">ЖК</label>
          <multiselect
            v-model="residential_complex"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :options="optionsResidentialComplexes"
            :multiple="true"
            @search-change="findResidentialComplex"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
        <div v-else class="form-group form-group-filter col-6">
          <label class="filter-modal-label">ЖК, ТЦ, ТРЦ, БЦ</label>
          <multiselect
            v-model="building"
            placeholder="Почніть вводити:"
            :close-on-select="false"
            :clear-on-select="true"
            :show-no-results="false"
            label="name"
            track-by="id"
            :show-labels="false"
            :options="optionsBuildings"
            :multiple="true"
            @search-change="findBuildings"
          >
            <span slot="noResult">Немає результатів!!!</span>
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group form-group-filter col-6">
          <label class="filter-modal-label"></label>
          <input
            type="text"
            placeholder="Літера"
            v-model="house_letter"
            class="form-input"
          />
        </div>
        <div
          v-if="$store.state.filter_header.buildingType !== 'house'"
          class="form-group form-group-filter col-6"
        >
          <label class="filter-modal-label"></label>
          <input
            type="number"
            placeholder="Корпус"
            v-model="house_hull"
            class="form-input"
          />
        </div>
      </div>
      <div class="row col-12">
        <div
          id="checkers"
          class="btn-group-toggle form-checkbox-b type-filter__even-number"
          data-toggle="buttons"
        >
          <label class="btn">
            <input
              type="checkbox"
              v-model="house_number_even"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Непарні</span>
          </label>
          <label class="btn">
            <input
              type="checkbox"
              v-model="house_number_odd"
              class="form-input"
            />
            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>
            <span>Парні</span>
          </label>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import FilterItemWrapper from '../FilterItemWrapper.vue';
import api from '../../../../api';

export default {
  name: 'FilterAddress',
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      timer: null
    };
  },
  methods: {
    ...mapActions([
      'handleGetCities',
      'handleGetDistrictByCities',
      'handleGetDistrict',
      'handleGetMicroDistrict',
      'handleGetStreet',
      'handleGetResidentialComplex',
      'handleGetBuildings'
    ]),
    ...mapMutations({
      changeDistrict: 'CHANGE_DISTRICT',
      changeMicrodistrict: 'CHANGE_MICRODISTRICT',
      changStreet: 'CHANGE_STREET',
      changResidentialComplex: 'CHANGE_RESIDENTIAL_COMPLEX',
      changBuilding: 'CHANGE_BUILDING',
      changHouseNumberEven: 'CHANGE_HOUSE_NUMBER_EVEN',
      changHouseNumberOdd: 'CHANGE_HOUSE_NUMBER_ODD',
      changHouseNumber: 'CHANGE_HOUSE_NUMBER',
      changNumberLetter: 'CHANGE_HOUSE_LETTER',
      changNumberHull: 'CHANGE_HOUSE_HULL',
      resetFilterAddress: 'RESET_FILTER_ADDRESS',
      changeCity: 'CHANGE_CITY'
    }),
    async getDistrictsByCities(id) {
      this.handleGetDistrictByCities(id);
    },
    findMicrodistrict(name) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleGetMicroDistrict({ name });
      }, 300);
    },
    findStreets(name) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleGetStreet({ name });
      }, 300);
    },
    findResidentialComplex(name) {
      if (name.length > 0 && name.length < 3) return;

      this.handleGetResidentialComplex({ name });
    },
    findBuildings(name) {
      if (name.length > 0 && name.length < 3) return;

      this.handleGetBuildings({ name });
    }
  },
  computed: {
    ...mapGetters({
      optionsCities: 'cities',
      optionsDistricts: 'districts',
      optionsMicroDistricts: 'microdistricts',
      optionsStreets: 'streets',
      optionsResidentialComplexes: 'residentialComplex',
      existsTurnOnFilterAddress: 'existsTurnOnFilterAddress',
      optionsBuildings: 'buildings',
      getForm: 'getForm'
    }),
    isEnabledSuburb({ city = [] }) {
      const [{ is_city: isCity = true } = {}] = city;
      return !isCity;
    },
    district: {
      get() {
        return this.$store.state.filter_address.form.district;
      },
      set(data) {
        this.changeDistrict(data);
      }
    },
    microdistrict: {
      get() {
        return this.$store.state.filter_address.form.microdistrict;
      },
      set(data) {
        this.changeMicrodistrict(data);
      }
    },
    city: {
      get({ $store: { state } }) {
        return state.filter_address.form.city;
      },
      set(value) {
        if (value) {
          this.changeCity([value]);
        }
      }
    },
    street: {
      get() {
        return this.$store.state.filter_address.form.street;
      },
      set(data) {
        this.changStreet(data);
      }
    },
    residential_complex: {
      get() {
        return this.$store.state.filter_address.form.residential_complex;
      },
      set(data) {
        this.changResidentialComplex(data);
      }
    },
    building: {
      get() {
        return this.$store.state.filter_address.form.building;
      },
      set(data) {
        this.changBuilding(data);
      }
    },
    house_number_even: {
      get() {
        return this.$store.state.filter_address.form.house_number_even;
      },
      set(val) {
        this.changHouseNumberEven(val);
      }
    },
    house_number_odd: {
      get() {
        return this.$store.state.filter_address.form.house_number_odd;
      },
      set(val) {
        this.changHouseNumberOdd(val);
      }
    },
    house_number: {
      get() {
        return this.$store.state.filter_address.form.house_number;
      },
      set(val) {
        this.changHouseNumber(val);
      }
    },
    house_letter: {
      get() {
        return this.$store.state.filter_address.form.house_letter;
      },
      set(val) {
        this.changNumberLetter(val);
      }
    },
    house_hull: {
      get() {
        return this.$store.state.filter_address.form.house_hull;
      },
      set(val) {
        this.changNumberHull(val);
      }
    }
  },
  watch: {
    optionsCities: {
      handler(cities) {
        if (cities.length > 1 && !this.city.length) {
          this.city = [cities[0]];
        }
      },
      immediate: true
    },
    city: {
      handler(city) {
        if (city && city.length > 0) {
          this.getDistrictsByCities(city[0].id);
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
#checkers {
  flex-direction: row;
}
</style>
