<template>
  <filter-item-wrapper
    title="Стан"
    icon="menu_icon5"
    collapse="collapse-condition"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterCondition"
        @click="resetFilterCondition"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <multiselect
          v-model="repair"
          placeholder="Стан"
          label="name"
          track-by="label"
          :close-on-select="false"
          :clear-on-select="false"
          :show-no-results="false"
          :show-labels="false"
          :options="choises_label"
          :multiple="true"
        >
        </multiselect>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import LabelsMixin from '@/mixins/LabelsMixin';
import Multiselect from 'vue-multiselect';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterCondition',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  methods: {
    ...mapMutations({
      changeCondition: 'CHANGE_CONDITION',
      resetFilterCondition: 'RESET_FILTER_CONDITION'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterCondition: 'existsTurnOnFilterCondition'
    }),
    repair: {
      get() {
        return this.$store.state.filter_condition.form.repair;
      },
      set(data) {
        this.changeCondition(data);
      }
    }
  }
};
</script>
